@import '../variables';

$layout-responsive-width: 1024px;

body {
  padding-top: $navbar-height;
  cursor: auto;
  letter-spacing: normal;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.page-header {
  margin: 0 0 15px;
}

.page-header h1 {
  padding-bottom: 0;
  margin: 0;
}

.tab-content {
  margin-top: 15px;
}

.page-break {
  page-break-after: always;
}

.xl-modal {
  .modal-dialog {
    @media (max-width: $layout-responsive-width) {
      width: 95vw;
    }
    @media (min-width: $layout-responsive-width) {
      width: 1024px;
    }
  }
}

.m-modal {
  .modal-dialog {
    width: 700px;
  }
}

.sticky-bottom-left {
  position: fixed;
  bottom: 0;
  left: 8px;
  padding: 5px 10px;
  background-color: $panel-default-heading-bg;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  z-index: 100;
  font-size: 11px;
  color: black;
  border-top: solid 1px $brand-primary;
  border-right: solid 1px $brand-primary;
  border-left: solid 1px $brand-primary;
}

.container-fill-height {
  display: table;
  width: 100%;
  height: 100vh;

  .container-content-middle {
    display: table-cell;
    vertical-align: middle;
  }
}

.container-app-page {
  padding-top: 35px;
  min-height: calc(100vh - #{$navbar-height});

  @supports (min-height: 100dvh) {
    min-height: calc(100dvh - #{$navbar-height});
  }

  @media (max-width: $layout-responsive-width) {
    padding-top: 10px;
  }
}

.settings-nav {
  padding: 2em;
  display: flex;
  flex-direction: row;
  color: #838eab;
  background-color: #fff;
  h1 {
    margin: 0;
    font-size: 16px;
  }
  ul {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0 1em;
      font-size: 25px;
      color: #b4bbce;
      cursor: pointer;
    }
  }
  .settings-account {
    text-align: right;
    flex-grow: 1;
    p {
      margin: 0.6em 0;
      font-weight: 700;
    }
  }
  .active {
    color: #14bae3;
  }
}
