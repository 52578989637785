.ui-mention-container {
  flex-flow: column nowrap;
  position: relative;

  textarea {
    background: none;
    display: block;
    position: relative;
    z-index: 2;
  }

  .ui-mention-highlight {
    display: block;
    overflow: hidden !important;
    white-space: pre-wrap;
    word-wrap: break-word;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    color: rgba(0, 0, 0, 0);
    z-index: 1;

    span {
      border-radius: 2px;
      background-color: lighten($brand-primary, 40%);
      border: 1px solid $brand-primary;
      display: inline;
      padding-left: 1px;
      margin-left: -2px;
    }

  }

}
