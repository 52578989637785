.fsp-source-list__item[title='Content Library'] {
  .fsp-source-list__logout {
    display: none;
  }
}

.fsp-cloud__files-container {
  .fsp-grid__cell {
    height: auto;
    max-height: 100px;
    min-height: 38px;

    img {
      object-fit: contain;
      height: 110px;
      max-width: 90px;
      width: 90px;
    }
  }
}
