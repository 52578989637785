@media print {

  .progress,
  .progress > .progress-bar {
    display: block !important;
    -webkit-print-color-adjust: exact !important;
    box-shadow: inset 0 0 !important;
    color: white !important;
  }

  .progress-bar-primary {
    background-color: $brand-primary !important;
  }

  .progress-bar-success {
    background-color: $brand-success !important;
  }

  .progress-bar-danger {
    background-color: $brand-danger !important;
  }

  img {
    max-width: none !important;
  }

  .scroll-y {
    overflow: visible !important;
  }

}
