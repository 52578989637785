.border-separated > [class*='col-'] {
  border-right: 1px solid $row-border-separator-color;
}

.border-separated > [class*='col-']:last-child {
  border-right: 0;
}

.border-left-gray-lighter,
.border-left-gray-lighter.active,
.border-left-gray-lighter.active:hover {
  @include border-left-variant($gray-lighter);
}

.border-left-success,
.border-left-success.active,
.border-left-success.active:hover {
  @include border-left-variant($brand-success);
}

.border-left-info,
.border-left-info.active,
.border-left-info.active:hover {
  @include border-left-variant(#5bc0de);
}

.border-gray-lighter {
  @include border-variant($gray-lighter);
}

.border-success {
  @include border-variant($brand-success);
}

.border-info {
  @include border-variant(#5bc0de);
}

.border-dark {
  border-color: #999;
}

.border-radius-bottom-none {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.border-bottom-none {
  border-bottom: 0;
}

.border-primary-dashed {
  border: dashed 2px $brand-primary;
}

.border-radius-none {
  border-radius: 0 !important;
}
