.navbar-actionable {

  .navbar-nav > li > a {
    padding: 14px 10px;
    height: 48px;
    overflow: no-display;
    font-size: 12px;
  }

  .navbar-nav > li.text-block > a {
    padding-top: 3px;
    padding-bottom: 0;
    overflow: no-display;
    display: table;
    table-layout: fixed;
  }

  .navbar-nav > li.text-block > a > i {
    padding: 15px 10px 0 0;
  }

  .navbar-nav > li.text-block > a > div {
    display: table-cell;
    vertical-align: middle;
    table-layout: fixed;
    overflow: hidden;
    height: 40px;
  }

  .navbar-nav > li.text-block > a > div > span {
    display: block;
    max-height: 45px;
  }

  .navbar-right {
    margin-right: 0;
  }

}
