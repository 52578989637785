.overlay-wrapper {

  .overlay-top {
    height: 0;
    overflow-y: hidden;
    transition-property: height;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    bottom: 0;
    width: 97%;
    position: absolute;
    background-color: #f5f8fa;

    .modal-content {
      height: 100%;
    }

    .modal-content.scroll-y {
      max-height: inherit;
    }

    .modal-body {
      height: 100%;
    }

  }

}

.overlay-wrapper.active {

  overflow: hidden !important;

  .overlay-top {
    position: absolute;
    bottom: 0;
    height: 100%;
    z-index: 10;
  }

}
