.statcard {
  padding: 30px;

  .statcard-number {
    margin-top: 0;
    margin-bottom: 0;
  }

  .statcard-desc {
    font-size: 85%;
    letter-spacing: 0.15em;
    color: #a9aebd;
    text-transform: uppercase;
  }
}

.statcard-primary {
  background-color: $brand-primary;
}

.statcard-success {
  background-color: $brand-success;
}

.statcard-info {
  background-color: #5bc0de;
}

.statcard-danger {
  background-color: $brand-danger;
}

.statcard-warning {
  background-color: $brand-warning;
}

.statcard-primary,
.statcard-success,
.statcard-info,
.statcard-danger,
.statcard-warning {
  color: #fff;
  border-radius: 3px;
}

.delta-indicator {
  display: inline-block;
  padding: 0.4em;
  font-size: 12px;
  vertical-align: middle;
  white-space: nowrap;
}

.delta-indicator.delta-positive::after,
.delta-indicator.delta-negative::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: '';
}

.delta-indicator.delta-positive {
  color: $brand-success;
}

.delta-indicator.delta-positive:not(.delta-inverse):after,
.delta-indicator.delta-negative.delta-inverse:after {
  /* stylelint-disable-line */
  border-top: 0;
  border-bottom: 4px solid;
}

.delta-indicator.delta-negative {
  color: $brand-danger;
}
