@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import 'colors.scss';

/* declare a font face for JoyPixels */
@font-face {
  font-family: JoyPixels;
  src: url('https://www.orlo.app/assets/fonts/joypixels.woff') format('woff'),
    url('https://www.orlo.app/assets/fonts/joypixels.woff2') format('woff2');
}
/* declare a font face for JoyPixels SVG font (Firefox only) */
@font-face {
  font-family: JoyPixelsSVG;
  src: url('https://www.orlo.app/assets/fonts/joypixels-svg.woff')
      format('woff'),
    url('https://www.orlo.app/assets/fonts/joypixels-svg.woff2') format('woff2');
}

@font-face {
  font-family: JoyPixels;
  src: url('https://www.orlo.app/assets/fonts/lato.woff2') format('woff2');
  /* using the unicode-range attribute to limit the reach of the JoyPixels web font */
  unicode-range: U+0000-00A8, U+00AF-00FF, U+0131, U+0152-0153, U+02BB-02BC,
    U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2191, U+2193, U+2212,
    U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: JoyPixelsSVG;
  src: url('https://www.orlo.app/assets/fonts/lato.woff2') format('woff2');
  /* using the unicode-range attribute to limit the reach of the JoyPixels web font */
  unicode-range: U+0000-00A8, U+00AF-00FF, U+0131, U+0152-0153, U+02BB-02BC,
    U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2191, U+2193, U+2212,
    U+2215, U+FEFF, U+FFFD;
}

/* OR font family applied to class matching *joypixels-font */
[class*='joypixels'],
textarea,
input {
  font-family: JoyPixels;
}
/* apply SVG font when browser is Mozilla Firefox */
@-moz-document url-prefix() {
  [class*='joypixels'],
  textarea,
  input {
    font-family: JoyPixelsSVG;
  }
}

h1 {
  margin-top: 0;
  font-size: 1.875em;
  font-weight: bold;
  line-height: 2.25em;
  font-family: 'Lato', 'Arial', sans-serif;
  &.light {
    color: $lmode-title-text;
  }
  &.dark {
    color: $dmode-title-text;
  }
}

h2 {
  margin-top: 0;
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1.5em;
  font-family: 'Lato', 'Arial', sans-serif;
  &.light {
    color: $lmode-title-text;
  }
  &.dark {
    color: $dmode-title-text;
  }
}

h3 {
  margin-top: 0;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.5em;
  font-family: 'Lato', 'Arial', sans-serif;
  &.light {
    color: $lmode-title-text;
  }
  &.dark {
    color: $dmode-title-text;
  }
}

h4 {
  margin-top: 0;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2em;
  font-family: 'Lato', 'Arial', sans-serif;
  &.light {
    color: $lmode-title-text;
  }
  &.dark {
    color: $dmode-title-text;
  }
}

p {
  font-family: 'Lato', 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25em;
  &.light {
    color: $lmode-body-text;
  }
  &.dark {
    color: $dmode-body-text;
  }
}
