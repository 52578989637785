// sentiment colors defined sass.variables.ts

$sentiment-positive-color-hover: darken($sentiment-positive-color, 5%);
$sentiment-semi-positive-color-hover: darken(
  $sentiment-semi-positive-color,
  5%
);
$sentiment-neutral-color-hover: darken($sentiment-neutral-color, 5%);
$sentiment-semi-negative-color-hover: darken(
  $sentiment-semi-negative-color,
  5%
);
$sentiment-negative-color-hover: darken($sentiment-negative-color, 5%);

.sentiment.positive-bg,
.progress-bar-sentiment-positive {
  background-color: $sentiment-positive-color;
}

.sentiment.semi-positive-bg {
  background-color: $sentiment-semi-positive-color;
}

.sentiment.neutral-bg,
.progress-bar-sentiment-neutral {
  background-color: $sentiment-neutral-color;
}

.sentiment.semi-negative-bg {
  background-color: $sentiment-semi-negative-color;
}

.sentiment.negative-bg,
.progress-bar-sentiment-negative {
  background-color: $sentiment-negative-color;
}

// new
.sentiment {
  &.positive-new {
    i {
      color: $sentiment-positive-color;
    }
  }

  &.semi-positive-new {
    i {
      color: $sentiment-semi-positive-color;
    }
  }

  &.neutral-new {
    i {
      color: $sentiment-neutral-color;
    }
  }

  &.semi-negative-new {
    i {
      color: $sentiment-semi-negative-color;
    }
  }

  &.negative-new {
    i {
      color: $sentiment-negative-color;
    }
  }

  &.no-sentiment {
    i {
      color: #838eab;
    }
  }
}

.sentiment.positive-bg:hover {
  background-color: $sentiment-positive-color-hover;
}

.sentiment.semi-positive-bg:hover {
  background-color: $sentiment-semi-positive-color-hover;
}

.sentiment.neutral-bg:hover {
  background-color: $sentiment-neutral-color-hover;
}

.sentiment.semi-negative-bg:hover {
  background-color: $sentiment-semi-negative-color-hover;
}

.sentiment.negative-bg:hover {
  background-color: $sentiment-negative-color-hover;
}

.bg-dark-primary {
  background-color: $bg-dark-primary;
}

.bg-dark-primary:hover {
  background-color: $bg-dark-primary-hover;
}

.bg-dark-secondary {
  background-color: $bg-dark-secondary;
}

.bg-dark-secondary:hover {
  background-color: $bg-dark-secondary-hover;
}

.bg-light-primary {
  background-color: $bg-light-primary;
}

.bg-dark {
  background-color: #999;
  color: #fff;
}

.bg-info-dark {
  background-color: $brand-primary;
}

.bg-success-dark {
  background-color: $brand-success;
}

.bg-success-dark {
  background-color: $brand-success;
}

.bg-warning-dark {
  background-color: $brand-warning;
}

.bg-danger-dark {
  background-color: $brand-danger;
}

.bg-primary-dark {
  background-color: $brand-primary;
}

.bg-light {
  background-color: #fff;
}
