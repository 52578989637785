.btn-inverse {
  color: #fff;
  background-color: $brand-inverse;
  border-color: rgba(0, 0, 0, 0.2);
}

.btn-royal {
  color: #fff;
  background-color: $brand-royal;
  border-color: rgba(0, 0, 0, 0.2);
}

.btn-danger {
  background-color: #e36635;
  border-color: #e36635;
}

.btn-outline {
  background-color: transparent;
}

.btn-outline.btn-primary {
  color: $brand-primary;
}

.btn-outline.btn-inverse {
  color: $gray;
}

.btn-outline.btn-primary:hover,
.btn-outline.btn-inverse:hover {
  color: #fff;
}

.btn-square {
  border-radius: 0;
}

.btn-rounded {
  border-radius: 20px;
}

.btn-padding-sides {
  $btn-padding: 30px;
  padding-left: $btn-padding;
  padding-right: $btn-padding;
}
