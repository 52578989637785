.nav-sidebar {
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 20px;

  .nav > li > a {
    color: #999;
    border-left: 2px solid transparent;
    padding: 4px 20px;
    font-size: 13px;
    font-weight: 400;
  }

  .nav .nav > li > a {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 30px;
    font-size: 12px;
  }

  .nav > .active > a,
  .nav > li > a:hover {
    color: $brand-primary;
    text-decoration: none;
    background-color: transparent;
    border-left-color: $brand-primary;
  }

  .nav > li > a:focus {
    background-color: transparent;
    outline: none;
  }

  .nav > .active > a,
  .nav > .active:hover > a,
  .nav > .active:focus > a {
    font-weight: 700;
  }

  .nav .nav > .active > a,
  .nav .nav > .active:hover > a,
  .nav .nav > .active:focus > a {
    font-weight: 500;
  }

  .nav ul.nav {
    display: none;
  }

  .nav > .active > ul.nav {
    display: block;
  }
}
