@import 'colors.scss';

.orlo-button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  color: #fff;
  font-family: 'Lato', 'Arial', sans-serif;
  font-size: 14px;
  font-weight: bold;
  &:focus {
    outline: 0;
  }
  &.primary {
    background-color: $primary-active;
    margin-right: 2.5%;
  }
  &.secondary {
    background-color: $secondary-active;
  }
  &.text {
    background: transparent;
    text-transform: uppercase;
    color: $lmode-body-text;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.8em;
  }
  &:disabled {
    background-color: #cfd3de;
  }
}

.orlo-table {
  margin: 0;
  border-radius: 25px;
  background-color: #fff;
  border: none !important;
  color: $lmode-body-text;
  &.striped {
    tr {
      &:nth-child(even) {
        background-color: #fff;
      }
      &:nth-child(odd) {
        background-color: $lmgrey-background;
      }
    }
  }
  tr {
    border-top: 2px solid $lmgrey-background;
    &:first-child {
      border-top: none;
    }
    &.has-delete {
      &:hover {
        box-shadow: 0px 0px 10px 5px #f3f3f3;
        border-radius: 10px;
        border-top-color: transparent;
        & + tr {
          border-top-color: transparent;
        }
      }
      a {
        position: relative;
        margin-right: 30px;
        margin-left: 15px;
        i,
        .icon-wrap {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 14px;
          color: #d4d7e2;
          line-height: 0;
          &:hover,
          &.visible {
            padding: 19px;
            border-radius: 50px;
            background-color: #faeeeb;
            color: #e29e8c;
            top: -8px;
            left: -8px;
          }
        }
      }
    }
  }
  th {
    // width:100%;
    padding: 0 25px 25px 25px !important;
    // display:block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 900;
    line-height: 2.5em;
    color: $lmode-helper-text;
    letter-spacing: 1px;
    box-sizing: border-box;
    border: none !important;
  }
  td {
    padding: 15px !important;
    border: none !important;
    word-wrap: break-word;
    &.small {
      font-size: 0.85em;
    }
    &.centered {
      text-align: center;
    }
  }
  tbody {
    td {
      &:first-child {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 0.9em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 300px;
      }
    }
  }
  thead {
    tr {
      background-color: #fff !important;
      border: none;
    }
    th {
      padding: 1em 15px !important;
      background-color: $lmgrey-background;
      color: $lmode-body-text;
      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.orlo-text-input {
  width: 100%;
  margin: 0 0 0.4em 0;
  padding: 0.6em 1.6em;
  border-radius: 50px;
  background-color: $lmgrey-background;
  border: 3px solid $lmgrey-background;
  font-size: 16px;
  font-weight: 900;
  box-sizing: border-box;
  &:focus {
    border-color: $primary-active;
    outline: 0;
  }
  &::placeholder {
    color: $lmode-helper-text;
    font-style: italic;
    font-weight: 400;
  }
  &.invalid {
    border-color: $primary-red;
    background-color: $primary-red;
    color: $secondary-red;
    &::placeholder {
      color: $secondary-red;
    }
  }

  &.no-right-radius {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.no-left-radius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.orlo-input-submit {
  text-align: center;
  white-space: nowrap;
  background-color: #caceda;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border: none;
  padding: 1.4rem 1.6rem;
  margin-bottom: 0.5em;
  color: #fff;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: $primary-active;
  }

  i {
    margin-right: 0.5rem;
  }
}

.orlo-select {
  margin: 0 0 0.4em 0;
  position: relative;
  i {
    position: absolute;
    top: 45px;
    right: 20px;
    font-size: 20px;
    color: $primary-active;
  }

  select {
    width: 100%;
    height: 46px;
    padding: 0.6em 1.6em;
    border-radius: 50px;
    background-color: $lmgrey-background;
    border: 3px solid $lmgrey-background;
    font-size: 16px;
    font-weight: 700;
    box-sizing: border-box;
    box-shadow: none;
    appearance: none;
    color: #838eab;
    cursor: pointer;
    &:focus {
      border-color: $primary-active;
      outline: 0;
    }
    &::placeholder {
      color: $lmode-helper-text;
      font-style: italic;
      font-weight: 400;
    }
    &::-ms-expand {
      display: none;
    }
    &.invalid {
      border-color: $primary-red;
      background-color: $primary-red;
      color: $secondary-red;
      &::placeholder {
        color: $secondary-red;
      }
    }

    &[disabled] {
      + i {
        color: #ced3df;
      }
    }
  }
}

.orlo-modal {
  .modal-content {
    border-radius: 25px;
    background-color: white;
    border: none;
    margin: auto;
  }

  &-1000 .modal-dialog {
    width: 1000px;
    max-width: 98%;
    margin-top: -5%;
  }

  &-1200 .modal-dialog {
    width: 1200px;
    max-width: 98%;
    margin-top: -5%;
  }

  &.position-top-20 {
    top: 20px;
    .modal-dialog {
      margin-top: 0;
    }
  }
}

.orlo-modal-backdrop {
  background-color: rgb(16, 21, 37);
  opacity: 0.7 !important;
}

.orlo-modal-dark {
  .modal-content {
    border-radius: 25px;
    background-color: $dmode-background;
    border: none;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #eff2f8 $primary-active;
}

.custom-scrollbar::-webkit-scrollbar-track {
  // background: #EFF2F8;
  background: #fff;
  border-radius: 6px;
}

.custom-scrollbar-2::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar-2 {
  scrollbar-width: thin;
  scrollbar-color: #ecf0fb $primary-active;
}

.custom-scrollbar-2::-webkit-scrollbar-track {
  // background: #EFF2F8;
  background: #fff;
  border-radius: 1px;
}

.orlo-textarea {
  width: 100%;
  label {
    width: 100%;
    padding: 0 3em;
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 900;
    line-height: 2.5em;
    color: $lmode-body-text;
    letter-spacing: 1px;
    box-sizing: border-box;
    margin-bottom: 5px;
  }

  textarea {
    width: 100%;
    margin: 0 0 0.4em 0;
    padding: 1.6em;
    border-radius: 10px;
    background-color: $grey-background;
    border: 3px solid transparent;
    font-size: 16px;
    font-weight: 700;
    box-sizing: border-box;
    color: $lmode-title-text;
    &:focus {
      border-color: $primary-active;
      outline: 0;
    }
    &::placeholder {
      color: $lmode-helper-text;
      font-style: italic;
      font-weight: 400;
    }
    &.invalid {
      border-color: $primary-red;
      background-color: $primary-red;
      color: $secondary-red;
      &::placeholder {
        color: $secondary-red;
      }
    }
  }
}

.orlo-tickbox {
  display: flex;
  align-items: center;
  position: relative;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    margin: 0;
    cursor: pointer;
  }

  label {
    width: 100%;
    padding: 0 1em;
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 900;
    line-height: 2.5em;
    color: $lmode-body-text;
    letter-spacing: 1px;
    box-sizing: border-box;
    margin-bottom: 5px;
  }

  &::before {
    display: block;
    margin-top: -6px;
    background-color: $lmgrey-background;
    content: '';
    border-radius: 50px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  i {
    display: none;
  }

  &.selected {
    label {
      color: $primary-active;
    }
    &::before {
      color: $primary-active;
      background-color: #d0f1f9;
    }
    i {
      display: block;
      position: absolute;
      left: 2px;
      top: 2px;
      bottom: 0;
      font-size: 18px;
      color: $primary-active;
    }
  }
  &:first-child {
    margin-right: 30px;
  }
}

.cdk-overlay-container {
  z-index: 1060;
}

cdk-overlay-pane {
  width: 100%;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #eff2f8 $primary-active;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #eff2f8;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: $primary-active;
  border-radius: 6px;
  border: 3px solid $primary-active;
}

// Accessibility
// -------------------------

.a11y-label {
  position: absolute;
  text-indent: -99999px;
  color: #000;
}

@media print {
  body {
    // remove pendo buttons when printing PDF
    > button {
      display: none !important;
    }
  }
}
