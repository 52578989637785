@import './variables';
@import './include-media';

@mixin border-left-variant($border-color) {
  border-left: solid 7px $border-color !important;
}

@mixin border-variant($border-color) {
  border: solid 4px $border-color;
}

@mixin align-vertical($height: 100%) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $height;
}

@mixin responsiveWidth($maxWidth) {
  @media only screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin responsiveHeight($maxHeight) {
  @media only screen and (max-height: $maxHeight) {
    @content;
  }
}

@mixin ie-only {
  // ie11 hack http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin box-shadow-bottom {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

@mixin box-shadow-top {
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.175);
}

@mixin slide-in-overlay($height: 100%) {
  position: absolute;
  width: 100%;
  height: $height;
  bottom: 0;
  left: 0;
  z-index: 2;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}

@mixin box-shadow-animated {
  box-shadow: $box-shadow-1;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: $box-shadow-4;
  }
}
