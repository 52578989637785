.list-group-virtual-scroll {

  width: 100%;
  cursor: pointer;

  .list-group-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 20px;
  }

  .list-group-item-text:hover {
    overflow: hidden;
    white-space: normal;
    max-height: 60px;
  }

}

.list-group-virtual-scroll:hover {
  z-index: 1000;
}

.list-group-virtual-scroll.active .text-muted,
.list-group-virtual-scroll.active small {
  color: white;
}
