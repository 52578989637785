$timeline-responsive-width: 885px;

.timeline {
  list-style: none;
  padding: 0 0 20px;
  position: relative;
}

.timeline::before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 3px;
  background-color: #eee;
  left: 50%;
  margin-left: -1.5px;
}

.hide-timeline-line {
  .timeline::before {
    display: none;
  }
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
  width: 50%;
  float: left;
  clear: left;
  @media (max-width: $timeline-responsive-width) {
    min-width: 90vw;
  }
}

.timeline > li::before,
.timeline > li::after {
  content: ' ';
  display: table;
}

.timeline > li::after {
  clear: both;
}

.timeline > li::before,
.timeline > li::after {
  content: ' ';
  display: table;
}

.timeline > li::after {
  clear: both;
}

.timeline > li .timeline-panel {
  width: calc(100% - 50px);
  float: left;
  border: 1px solid #d4d4d4;
  position: relative;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  border-radius: 2px;
}

.timeline > li .timeline-panel .timeline-inner {
  padding: 20px;
  background-color: #fff;
  padding-bottom: 0;
}

.timeline > li .timeline-panel::before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: ' ';
}

.timeline > li .timeline-panel::after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: ' ';
}

.timeline > li .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  right: -25px;
  margin-left: -13px;
  background-color: #999;
  z-index: 100;
  border-radius: 50%;
}

.timeline > li.timeline-inverted .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted .timeline-panel::before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
  top: 90px;
}

.timeline > li.timeline-inverted .timeline-panel::after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
  top: 91px;
}

.timeline-badge a {
  color: #fff !important;
}

.timeline-badge a:hover {
  color: #000 !important;
}

.timeline-badge.primary {
  background-color: #2e6da4 !important;
}

.timeline-badge.success {
  background-color: #3f903f !important;
}

.timeline-badge.warning {
  background-color: #f0ad4e !important;
}

.timeline-badge.danger {
  background-color: #d9534f !important;
}

.timeline-badge.info {
  background-color: #5bc0de !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-heading .avatar {
  margin-right: 15px;
}

h4.timeline-title a {
  color: #337ab7 !important;
}

.timeline-body ul {
  padding: 20px;
  margin-bottom: 0;
}

.timeline-body p + p {
  margin-top: 5px;
}

.timeline-body {
  word-wrap: break-word;
}

.timeline-body p img {
  max-width: 100%;
}

.timeline-footer {
  background-color: #f4f4f4;
}

.timeline-footer a {
  cursor: pointer;
  text-decoration: none;
}

.timeline > li.timeline-inverted {
  float: right;
  clear: right;
}

.timeline > li:nth-child(2) {
  margin-top: 60px;
}

.timeline > li.timeline-inverted .timeline-badge {
  left: -12px;
  top: 80px;
}

.timeline-date-divider {
  height: 150px;
}

.timeline-single-column-left {
  padding: 0;
}

.timeline-single-column-left::before {
  left: 28px;
}

.timeline-single-column-left {
  margin-top: -20px;
}

.timeline-single-column-left.first {
  margin-top: 10px;
}

.timeline-single-column-left > li {
  width: calc(100% - 25px);
}

.timeline-single-column-left > li .timeline-badge {
  left: 15px;
}

.timeline-single-column-left > li .timeline-panel {
  float: right;
  left: 20px;
}

.timeline-single-column-left > li .timeline-panel::before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline-single-column-left > li .timeline-panel::after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.single-monitoring-item .timeline li {
  width: 100% !important;
}

.single-monitoring-item .timeline-badge,
.single-monitoring-item .pagination,
.single-monitoring-item .timeline::before,
.single-monitoring-item .timeline-panel::after,
.single-monitoring-item .timeline-panel::before,
.single-monitoring-item .sticky-icon,
.single-monitoring-item .nav-tabs,
.single-monitoring-item h2 small {
  display: none !important;
}
