.flex-grid {
  display: flex;
  flex-wrap: wrap;
  .col {
    flex: 1;
    &.full {
      flex-basis: 100%;
    }
    &.one-third {
      flex-basis: 33.33%;
    }
    &.two-thirds {
      flex-basis: 66.66%;
    }
    &.one-half {
      flex-basis: 50%;
    }
  }
  &.thirds {
    justify-content: space-between;
    .col {
      width: 32%;
    }
  }
}
